import React, {  useEffect, useState } from "react"
import "./Referral.less"
import {
  Button,
  Col,
  Modal,
  Row,
  Skeleton,
  Typography,
  Form,
  Input,
  message,
} from "antd"
import SignOut from "../../assets/sidebar/signOut.svg"
import DefaultUser from "../../assets/mentroWebApp/defaultUser.svg"
import ErrorMessage from "../../assets/mentroWebApp/errorMessage.svg"
// import { AuthContext } from "../../context/AuthContext"
// import { navigate } from "gatsby-link"
import { getHttpHandler, postHttpHandler } from "../../utils/httpHandler"
import { useLoggedInUserStore } from "../ZustandStateManagment/UserData/store"

const { Text, Title } = Typography

const Referral = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [withDrawStatusLoading, setWithDrawStatusLoading] =
    useState<boolean>(false)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [userData, setUserData] = useState<any>(null)
  const authContext = useLoggedInUserStore()
  const [form] = Form.useForm()

  const getUserDetails = async () => {
    if (!authContext?.token) {
      return
    }
    setIsLoading(true)
    try {
      const userData = await getHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/users/getReferralData`,
        idToken: authContext?.token,
      })
      setUserData(userData.user)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      message.error("Something went wrong")
    }
  }

  const withDrawPayout = async () => {
    setWithDrawStatusLoading(true)
    try {
      const responseData = await postHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/mentor/payout`,
        idToken: authContext?.token,
      })
      if (responseData.success) {
        getUserDetails()
        return setWithDrawStatusLoading(false)
      }
      message.error(responseData.message)
      setWithDrawStatusLoading(false)
    } catch (error: any) {
      setWithDrawStatusLoading(false)
      message.error("Something went wrong")
    }
  }

  const setPayout = async () => {
    const payoutData = form.getFieldsValue()
    const { accName, accNum, accIFSC, upi } = payoutData

    try {
      const response = await fetch(
        `${process.env.GATSBY_BE_URL}/mentor/setPayoutInfo`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authContext?.token}`,
          },
          body: JSON.stringify({
            accName,
            accNum,
            accIFSC,
            upi,
          }),
        }
      )
      const responseData = await response.json()
    } catch (error) {
      message.error("Something went wrong")
    }

    setIsModalVisible(false)
  }
  useEffect(() => {
    window.location.replace("/")
  }, [])

  useEffect(() => {
    if (userData && !isLoading) {
      return
    }
    getUserDetails()
  }, [authContext.token])

  return (
    <div className="referralParentDiv">
      <Modal
        className="payoutModal"
        visible={isModalVisible}
        onOk={() => setPayout()}
        onCancel={() => setIsModalVisible(false)}
        okText={"WithDraw"}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={setPayout}
          onFinishFailed={error => {}}
          autoComplete="off"
        >
          <Form.Item
            label={<Text className="text">ACCOUNT NAME</Text>}
            name="accName"
            wrapperCol={{ span: 24 }}
            rules={[
              {
                whitespace: true,
                required: true,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter your Account Name
                  </Text>
                ),
              },
            ]}
          >
            <Input placeholder="Enter your Account Name" />
          </Form.Item>
          <Form.Item
            label={<Text className="text">ACCOUNT NUMBER</Text>}
            name="accNum"
            wrapperCol={{ span: 24 }}
            rules={[
              {
                whitespace: true,
                required: true,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter your Account Number
                  </Text>
                ),
              },
            ]}
          >
            <Input placeholder="Enter your Account Number" />
          </Form.Item>
          <Form.Item
            label={<Text className="text">ACCOUNT IFSC</Text>}
            name="accIFSC"
            wrapperCol={{ span: 24 }}
            rules={[
              {
                whitespace: true,
                required: true,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter your Account IFSC
                  </Text>
                ),
              },
            ]}
          >
            <Input placeholder="Enter your Account IFSC" />
          </Form.Item>
          <Form.Item
            label={<Text className="text">UPI</Text>}
            name="upi"
            wrapperCol={{ span: 24 }}
            rules={[
              {
                whitespace: true,
                required: true,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter your UPI
                  </Text>
                ),
              },
            ]}
          >
            <Input placeholder="Enter your UPI" />
          </Form.Item>
        </Form>
      </Modal>
      <div className="referralDiv">
        <div className="logoutDiv" onClick={authContext.logout}>
          <img src={SignOut} alt="logout icon" />
          <Text className="text">Log out</Text>
        </div>
        <div className="profilePicture">
          <img
            src={userData?.imageUrl ? userData?.imageUrl : DefaultUser}
            alt="user"
          />
          <Text className="name">
            {isLoading ? (
              <Skeleton.Input className="nameSkel" active size="small" />
            ) : (
              userData?.userName
            )}
          </Text>
        </div>
        <div className="incomeDiv">
          <Title className="income">
            Total Income:
            {isLoading ? (
              <Skeleton.Button active size="small" />
            ) : (
              ` ₹${userData?.income}`
            )}
          </Title>
          <Button
            type="text"
            className="redeemBtn"
            onClick={withDrawPayout}
            loading={withDrawStatusLoading}
          >
            Withdraw
          </Button>
        </div>
        <div className="promoCode">
          <Title className="promoTitle">YOUR PROMO CODES</Title>
          <Row className="promoRow">
            {userData &&
              Object.keys(userData?.promoCodeDetails).map((objKey, ind) => (
                <Col
                  key={ind}
                  xs={12}
                  className={ind % 2 !== 0 ? "promoColRight" : "promoColLeft"}
                >
                  <div className="promoCard" key={objKey}>
                    <Title className="label">
                      {userData?.promoCodeDetails[objKey].label}
                    </Title>
                    <Title className="content">
                      Redeems Left:{" "}
                      <span>
                        {userData?.promoCodeDetails[objKey].redeemsLeft}
                      </span>
                    </Title>
                    <Title className="content">
                      Total Redeem:{" "}
                      <span>
                        {userData?.promoCodeDetails[objKey].totalRedeem}
                      </span>
                    </Title>
                    <Title className="content">
                      Discount:{" "}
                      <span>
                        {userData?.promoCodeDetails[objKey].discountPercent}%
                      </span>
                    </Title>
                    <Title className="content">
                      Total Commission:{" "}
                      <span>
                        {userData?.promoCodeDetails[objKey].commisionPercent}%
                      </span>
                    </Title>
                    <Title className="content">
                      Total Commission:{" "}
                      <span>
                        {userData?.promoCodeDetails[objKey].totalCommision}
                      </span>
                    </Title>
                  </div>
                </Col>
              ))}
          </Row>
          <Row className="promoRow">
            {isLoading && (
              <>
                <Col xs={12} className="promoColLeft">
                  <div className="promoCard">
                    <Skeleton.Input className="promo1" active size="small" />
                    <Skeleton.Input className="promo2" active size="small" />
                    <Skeleton.Input className="promo3" active size="small" />
                    <Skeleton.Input className="promo4" active size="small" />
                    <Skeleton.Input className="promo2" active size="small" />
                    <Skeleton.Input className="promo5" active size="small" />
                  </div>
                </Col>
                <Col xs={12} className="promoColRight">
                  <div className="promoCard">
                    <Skeleton.Input className="promo1" active size="small" />
                    <Skeleton.Input className="promo2" active size="small" />
                    <Skeleton.Input className="promo3" active size="small" />
                    <Skeleton.Input className="promo4" active size="small" />
                    <Skeleton.Input className="promo2" active size="small" />
                    <Skeleton.Input className="promo5" active size="small" />
                  </div>
                </Col>
              </>
            )}
          </Row>
        </div>
      </div>
    </div>
  )
}

export default Referral
